  /* **********************************************************************************************
     Define data objects for the UI
     ************************************************************************************************
  */
     
     let processDivArray = [];

     processDivArray.push({
       key: 100,
       project: 1,
       id: 'The problem',
       description: 'The interface was',
       backgroundColor: 'white',
       border: 'black',
       content: [{
        imagePath: '../images/genetics.png',
        imageDescription: 
         'In the mortgage process, it is important to check that borrowers are employed. It typically happens twice, once close to the beginning of the process and once close to the end. ' + 
         'A third party vendor is used for this Verification of Employment (VOE) check. Orders are placed using a single dropdown hidden in a contextual, transient interface that only experienced users would be able to find.',
        },
        {
        imagePath: '',
        imageDescription: 'Issues: ' + 
        '\n VOE ordering functionality is not centrally located. ' +
        '\n' +
        '\n The dropdown options are completely out of context and confusing. ' +
        '\n' +
        '\n If you already ordered a VOE and you need to order again, you have to select \"Fulfillment to Complete\" and then reselect \"Vendor to Complete\”. No one could possibly know this.' +
        '\n' +
        '\n There is no indication of the order status. ' + 
        '\n' + 
        '\n If you want to cancel the order, select \"Fulfillment to Complete\", but there is nothing that tells the user whether it CAN be cancelled, or the status if a cancellation is pursued.' +
        '\n' +
        '\n The \"Vendor to Rush\" option only works one time, but remains in the dropdown always...and sometimes is not even a valid option.' +
        '\n' +
        '\n The \"Special Instructions to Vendor\" text is only sent when the VOE order is placed. No explanation or control over this is given in the interface.'
        }]
     });
   
     processDivArray.push({
       key: 101,
       project: 1,
       id: 'User Interviews',
       description: 'this is the actual interface',
       backgroundColor: 'white',
       border: 'black',
       content: [
        {
        imagePath: '../images/genetics.png',
        imageDescription: 'Through interviews with users and Process Owners, I learned about their experiences with ordering VOE: ' +
        '\n' +
        '\n There was still a need for Fulfillment team members to manually complete verification of employment (no vendor)' +
        '\n' +
        '\n I was informed that strict controls over who should and should not place orders were desired.' +
        '\n' +
        '\n I learned there was a user perception that any entry in the Special Instructions to Vendor text area was sent to the vendor, regardless of the order status. (not true)' +
        '\n' +
        '\n We knew that users were happy with and understood the appraisal ordering UI. This is a very similar process.' +
        '\n' +
        '\n Cancelling VOEs was necessary and needed to be obvious and controlled.' +
        '\n' +
        '\n I strongly felt that the transition from old to new functionality had to be seamless; easy to train and little interference with regular daily processing.'
        },
        {
         imagePath: '',
         imageDescription: 'We decided to baseline the new interface by using the appraisal ordering interface as a recognizable model. Immediately the users would be presented with a familiar interface. ',
         },]
     });
   
     processDivArray.push({
      key: 102,
      project: 1,
      id: 'Analysis 2',
      description: 'this is the actual interface',
      backgroundColor: 'white',
      border: 'black',
      content: [
       {
       imagePath: '',
       imageDescription: 'this is step 2 and some text for it',
       },
       {
       imagePath: '',
       imageDescription: 'this is step 2.1 and some text for this as well',
       }]
    });



     processDivArray.push({
       key: 103,
       project: 2,
       id: 'element1',
       description: 'This is Step 1',
       posX: '300px',
       posY: '150px',
       backgroundColor: 'white',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 104,
       project: 2,
       id: 'element2',
       description: 'This is Step 2',
       posX: '600px',
       posY: '150px',
       backgroundColor: 'white',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 105,
       project: 2,
       id: 'element3',
       description: 'This is Step 3',
       posX: '900px',
       posY: '150px',
       backgroundColor: 'white',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 106,
       project: 3,
       id: 'element1',
       description: 'This is TEST 1',
       posX: '900px',
       posY: '150px',
       backgroundColor: '#DDDDDD',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 107,
       project: 3,
       id: 'element1',
       description: 'This is TEST 2',
       posX: '900px',
       posY: '150px',
       backgroundColor: '#DDDDDD',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 108,
       project: 3,
       id: 'element1',
       description: 'This is TEST 3',
       posX: '900px',
       posY: '150px',
       backgroundColor: '#DDDDDD',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });
   
     processDivArray.push({
       key: 109,
       project: 3,
       id: 'element1',
       description: 'This is TEST 4',
       posX: '900px',
       posY: '150px',
       backgroundColor: '#DDDDDD',
       border: 'black',
       content: [
        {
        imagePath: '',
        imageDescription: 'some text',
        },
        {
        imagePath: '',
        imageDescription: 'some text',
        }]
     });

     export default processDivArray;
   