  /* **********************************************************************************************
     Define data objects for the UI

    let id: string = props.id;
    let name: string = props.content;
    let w: number = props.width;
    let h: number = props.height;
    let func: any = props.handler;

     ************************************************************************************************
  */
     
     let menuArray = [];

     menuArray.push({
      id: 'menu01',
      name: 'ABOUT' ,
      description: 'Polymath, Technophile, Metaversian',
      links: [
        ['Contact', 'contact'], 
        ['AI', 'ai'], 
        ["Somniumspace", "https://www.frankenzilla.com"],
      ],
    });

     menuArray.push({
       id: "menu02",
       name: "HIRE",
       description: "I am currently a Product Manager/Product Owner with an A-CSPO certification. I have years of experience as a Business and Systems Analyst. I am also a Developer.",
       links: [
         ["Highlights", "main"], 
         ["Experience", "experience"],
         ["Skill Set","skillset"],
         ["Certifications","certifications"],
       ],
     });


     menuArray.push({
      id: "menu03",
      name: "CX/UX",
      description: "Designing customer and user experiences since 2000. With my illustration / graphic design background I'm proficient in Figma, Adobe Photoshop and Illustrator...",
      links: [
        ["CX for VCI", "cxContent"], 
        ["Icon Design", "icon"],
        ["UX Analysis","uxAnalysis"],
      ],
    });


    menuArray.push({
      id: "menu04",
      name: "CERTS",
      description: "I have a unique set of talents: classically trained illustrator with a fine arts background. I have extensive video editing and film making background, 3D animation and Photography...",
      links: [
        ["Artwork", "art"], 
        ["Photos", "photos"],
        ["sonofpeter.art","https://sonofpeter.art/"],
      ],
    });



     export default menuArray;
   