import React from 'react';
/** 
 * Import tools and libraries
 */
import $ from "jquery";
import Enumerable from '../lib/linq.min';

/*
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrowUpRight } from '@fortawesome/fa-square-arrow-up-right';
*/

  const handleLinkClick = (e) => {
    $('#menuDiv').slideUp(300);
    let targetElement = $("#" + e.target.id);
    let divChild = targetElement.children();
    $(divChild[0]).slideToggle(300);

    if (e.target.id) {
    let targetLocation: string;
    let targetElement;
    let content;
    let targetChildren;

    targetLocation = e.target.id + "Content";
    
    // https://dmitripavlutin.com/foreach-iterate-array-javascript/#6-iterate-array-like-objects-using-foreach
    
    targetChildren = $("#"+targetLocation).children();
    const targetChildrenArray = Array.from(targetChildren);

    console.log("***** targetLocation *****");
    console.log(targetLocation);

    if (targetLocation) {
      //content = document.getElementById(targetLocation).innerHTML;
      //document.getElementById("contentDiv").innerHTML = content;

      targetElement = $(targetLocation);
      targetElement.css('display', 'inline');

    }
  }
  else {
    alert('handleLinkClick oopsy!!');
  }

  };



  /** 
   * @abstract This is the list of links provided by the menu
   * @param props
   * @description parameters: linkObject
   */
  function NavigationSubMenuDiv <HTMLDivElement>(props: any) {

    let linkArray = props.linkObject;
    let linkList = [];
    let indicator: any;

    if (linkArray && linkArray.length > 0) {
      <ul>
      {
        linkArray.map((item) => {
          // https://fontawesome.com/docs/web/use-with/react/
          //if (item[1].includes("http")) { indicator = <FontAwesomeIcon icon="fa-solid fa-square-arrow-up-right" /> };
          if (item[1].includes("http")) indicator = " ->"
          linkList.push(<li><div 
            style={{padding: '0px 0px 0px 4px'}} 
            id={item[1]} 
            onClick={handleLinkClick}>{item[0]}{indicator}</div></li>)
          })
      }
      </ul>
    }

    return(<div className="menuSubDiv"><ul> {linkList} </ul></div>);
  }


  /** 
   * @abstract Creates a menu div box with rounded corners and some kind of content. 
   * @param props
   * @description parameters: id, content, handler, mouseOverHandler
   * @example <NavigationMenuDiv id='menu01' name='Resume' description='about me' handler={handleMenuClick} mouseOverHandler={revealDescription}>
   */
  function NavigationMenuDiv <HTMLDivElement>(props: any) {
    /** 
     * define content properties of the div 
     */
    let id: string = props.id;
    let subId: string = "sub" + props.id
    let name: string = props.name;
    let descriptionId = props.id + "Description";
    let description: string = props.description;

    // define reactions to events
    let mouseOver: any = props.mouseOverHandler;
    let mouseOut: any = props.mouseOutHandler;
    let mouseclick: any = props.mouseClickHandler;
    let mouseLeave: any = props.mouseLeave;

    let linkList: any = props.links;
    let linkName: string = props.id + "Content";
    
    /** define the style object to be used in the div */
    const divStyle: any = {
      zIndex: '999',
      margin: '5px',
    };

    /** 
     * define the div itself 
     */
    return ( 
        <div
          //onMouseEnter={mouseOver} 
          onMouseOver={mouseOver}
          onMouseLeave={mouseLeave} 
          onMouseOut={mouseOut}
          //onClick={handleLinkClick}
          //onClick={mouseclick}
          style={divStyle}>
            
          <div id={id} className="menuDiv" onClick={handleLinkClick}> 
            {name} 
            <NavigationSubMenuDiv id={subId} linkObject={linkList} />
            <div id={descriptionId} style={{display: 'none'}}>{description}</div>
          </div>
        </div>
    );
  }

  export default NavigationMenuDiv;