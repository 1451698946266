/* eslint-disable @typescript-eslint/no-unused-vars */


/* 
**************************************************************************
To run in browser...
1. using console, navigate to dev directory 
2. navigate to reactProject directory
3. run 'npm start' to start the server
4. point browser at http://localhost:3000/ (if it doesn't automatically)

Background information
Folder structure:
https://blog.webdevsimplified.com/2022-07/react-folder-structure/
https://scrimba.com/articles/react-project-structure/#:~:text=The%20structure%20is%20organized%20into,styles%20folder%20contains%20CSS%20styles.

**************************************************************************
*/


/** 
 * Import react libraries
 */
import React from 'react';
import './App.css';


/** 
 * Import data
 */
// https://www.typescriptlang.org/docs/handbook/2/modules.html#additional-import-syntax
import {controlOptions} from './links';
import processDivArray from './data/ProcessData';
import menuArray from './data/MenuData';


/** 
 * Import custom react components
 */
import ImageDiv from './components/ImageDiv';
import ProcessStepDiv from './components/ProcessStepDiv';
import NavigationMenuDiv from './components/NavigationMenuDiv';
import ResumeDiv from './components/ResumeDiv';


/** 
 * Import tools and libraries
 */
import $ from "jquery";
import Coloris from './lib/coloris';
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import Enumerable from './lib/linq.min';



const projectList = [
  {
    id: 1,
    name: 'Managing Verification of Employment',
  },
  {
    id: 2,
    name: 'A Rack Editor',
  },
  {
    id: 3,
    name: 'Interface redesign',
  },
];

let divCounter: number = 0;

const canvasSize = { 
  width: 1000, 
  height: 400, 
}


const initialNoteList = [
  {
    title: 'blah',
    content: 'blah',
  }
];

const tmpCoordinates = {
  x: 0,
  y: 0,
  div: null,
}

const panelCoords = [-12.6, -4.3, 4.3, 12.6];


const noteOptions = {
  x: 50,
  y: 100,
  w: 200,
  h: 200,
  title: 'blah',
  content: 'this is my new thingy',
};

let noteObj = {};
let processStepDivDisplayArray = [];
let processStepDivImageArray = [];
let processStepDivImage = null;
let selectedDiv = [];

    /** Default configuration **/

    Coloris({
      el: '.coloris',
      swatches: [
        '#264653',
        '#2a9d8f',
        '#e9c46a',
        '#f4a261',
        '#e76f51',
        '#d62828',
        '#023e8a',
        '#0077b6',
        '#0096c7',
        '#00b4d8',
        '#48cae4'
      ]
    });

    /** Instances **/

    Coloris.setInstance('.instance1', {
      theme: 'pill',
      themeMode: 'dark',
      formatToggle: true,
      closeButton: true,
      clearButton: true,
      swatches: [
        '#a9ff73',
        '#2a9d8f',
        '#e9c46a',
        '#f4a261',
        '#e76f51',
        '#d62828',
        '#023e8a',
        '#0077b6',
        '#0096c7',
        '#00b4d8',
        '#48cae4'
      ]
    });
    

/** ********************
 *  FUNCTIONS
 *  ********************
 */
var greaterThanTen: any = (num) => {
  let out: number | string;
  if (num >= 10) { 
    out = num 
  } 
  else { 
    out = "0" + num; 
  }

  return out;
}

// create code for current time
var getTimeNow = () => {
  var time = [];
  var currentDate = new Date();
  var hour = greaterThanTen ( currentDate.getHours() );
  time.push ( hour );
  var minute = greaterThanTen ( currentDate.getMinutes() );
  time.push ( minute );
  var second = greaterThanTen ( currentDate.getSeconds() );
  time.push ( second );

  return time.join(":");
}

var getSecondsNow = () => {
  var currentDate = new Date();
  var second = greaterThanTen ( currentDate.getSeconds() );
  return second;
}

// https://stackoverflow.com/questions/36497843/what-is-the-array-type-for-html-elements-in-typescript
//var divList : Array<HTMLDivElement>;


  // Dragging
  // https://www.kindacode.com/article/react-typescript-drag-and-drop/#google_vignette
  // https://stackoverflow.com/questions/72309926/how-can-i-get-the-data-attribute-in-typescript-with-react-drag-and-drop
  // https://www.programcreek.com/typescript/?api=react.DragEvent
  

  // https://stackoverflow.com/questions/71313587/what-is-the-equivalent-of-dragstart-event-datatransfer-setdata-for-touchstart
  // create a custom object that temporarily stores then destroys drag information, as you can only have
  // one drag event happening at any given time, regardless of how many objects are being dragged.
  // you can even use react to detect changes in the value


  // https://reactjs.org/docs/components-and-props.html
  // https://stackoverflow.com/questions/61226729/pass-style-as-props-in-react-component

  // deals with the curious issue of the div snapping back in place, plus the delay
  // https://github.com/react-dnd/react-dnd/issues/3115




  function ProcessStepDivConnector <HTMLDivElement>(props: any) {
    
    /** define content properties of the div */
    let name: string = props.name;
    
    /** define the style object to be used in the div */
    const divStyle: any = {
      width: '99%',
      borderBottom: '1px solid black',
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      marginBottom: '20px'
    };

    /** define the div itself */
    return (<div style={divStyle}>&nbsp;</div>);
  }
    































/* ************************************************************************************************
   ************************************************************************************************
   ************************************************************************************************


    MAIN APP


   ************************************************************************************************
   ************************************************************************************************
   ************************************************************************************************
*/



function App() {
  console.log("------ CALLING APP() ------------");
  // console.log("current time: " + getTimeNow());
  let co: controlOptions = new controlOptions();


  /** React hook: a simple state
    
    example of how to use state in a function component 
    with the useState hook
    https://www.robinwieruch.de/react-usestate-hook/

  
  This JavaScript syntax (const [val1, val2]) is called “array destructuring”. It means that we’re making 
  two new variables count and setCount, where count is set to the first value returned by useState, and 
  setCount is the second. 
  
  When we declare a state variable with useState, it returns a pair — an array with two items. The first 
  item is the current value, and the second is a function that lets us update it. Using [0] and [1] to 
  access them is a bit confusing because they have a specific meaning. This is why we use array 
  destructuring instead.
  */

  //const [list, setList] = React.useState(initialList);
  const [count, setCount] = React.useState(1);
  const [list, setList] = React.useState<any[]>([]);
  const [value, setValue] = React.useState("");

  // UI configuration
  const [backgroundColorValue, setBackgroundColorValue] = React.useState("");
  const [buttonColorValue, setbuttonColorValue] = React.useState("");
  const [buttonTextColorValue, setButtonTextColorValue] = React.useState("");
  // const [projectName, setProjectName] = React.useState(projectList[count]);

  // create temporary values for currently dragged items
  const [draggedDiv, setDiv] = React.useState<string>(tmpCoordinates.div);
  const [elementGroup, setElementGroup] = React.useState<number>(0);
  const [flowStepObject, setflowStepObject] = React.useState<any>([]);

  // https://stackoverflow.com/questions/59125973/react-typescript-argument-of-type-is-not-assignable-to-parameter-of-type
  var noteArray: Array<HTMLDivElement>[];
  const [divList, setDivList] = React.useState<any[]>([]);

  // plane position value
  const [planeX, setPlaneX] = React.useState(1);
  const [planeY, setPlaneY] = React.useState(0);
  const [planeZ, setPlaneZ] = React.useState(0);
  const [planeDirection, setPlaneDirection] = React.useState(1);
  

  const addDivToList = (d) => {
    console.log("adding to list: " + d);

    var dm = document.getElementById('12345');
    dm.append(d);
    
    let tempArr: any[] = divList;
    tempArr.push(d);
    // call the React useState
    setDivList(tempArr);
    // update a status value
    setValue("div array: " + divList.length);
  };


  //Change the CSS Variable value here
  function valueChange(id, value) {
    const style = document.documentElement.style;
    style.setProperty('--' + id, value);
  }

  
// https://bobbyhadz.com/blog/react-type-is-not-assignable-to-type-never
// - never[] - an array that will always be empty, which is not what we want.
  const addToList = () => {
    let tempArr: any[] = list;
    tempArr.push(value);
    setList(tempArr);
    setValue("");
  };


  const deleteItem = (index) => {
    let temp = list.filter((item, i) => i !== index);
    setList(temp);
  };



  /** Hooks and Function Components
   
    Read more: https://www.java67.com/2022/01/how-to-create-dynamic-list-in-react.html
    function components in React look like this:

      const Example = (props) => 
      {
        // You can use Hooks here!
        return <div />;
      } 
    OR
      function Example(props) 
      {
        // You can use Hooks here!
        return <div />;
      }

    Hooks don’t work inside classes. But you can use them instead of writing classes.

    https://reactjs.org/docs/hooks-state.html
    What is a Hook? A Hook is a special function that lets you “hook into” React features. 
    For example, useState is a Hook that lets you add React state to function components. 

    What does calling useState do? It declares a “state variable”. This is a way to “preserve” some 
    values between the function calls. Normally, variables “disappear” when 
    the function exits but state variables are preserved by React.

    What do we pass to useState as an argument? The only argument to the useState() Hook 
    is the initial state. Unlike with classes, the state doesn’t have to be an object. We 
    can keep a number or a string if that’s all we need. In our example, we just want a 
    number for how many times the user clicked, so pass 0 as initial state for our variable. 
    (If we wanted to store two different values in state, we would call useState() twice.)

    What does useState return? It returns a pair of values: 
    - the current state
    - a function that updates it. 
    This is why we write const [count, setCount] = useState(). This is similar 
    to this.state.count and this.setState in a class, except you get them in a pair. If you’re 
    not familiar with the syntax we used, we’ll come back to it at the bottom of this page.
  */

  const handleIncrease = () => {
    console.log('handleIncrease');
    if (count < 3) {
      setCount(count + 1);
      setElementGroup(count + 1)
      setValue("Increasing");
    }
  };

  const handleDecrease = () => {
    console.log('handleDecrease');
    if (count > 1) {
      setCount(count - 1);
      setElementGroup(count - 1)
      setValue("Decreasing");
    }
  };

  const handlePositionChange = (x: number) => {
    console.log('handlePositionChange: ' + x);
    if (x && x<5) {
      setPlaneX(panelCoords[x-1]);
    }
  }

  // ============ DRAG AND DROP ============

  // This function will be triggered when you start dragging
  const dragStartHandler = ( ev: React.DragEvent<HTMLDivElement>) => {
    console.log("React dragStartHandler ---------------------------------");
    ev.dataTransfer.setData("text", ev.currentTarget.id);
  };

  // This function will be triggered when dragging
  const dragHandler = (ev: React.DragEvent<HTMLDivElement>) => { 
    console.log("React dragHandler ---------------------------------");
  }

  const mouseUpHandler = (ev: React.MouseEvent) => {
    setValue("React: mouse is UP!!");
  }

  // This function will be triggered when dropping 
  const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => { 
    console.log("React dropHandler ---------------------------------");
    
    // set local values from temporary ones
    let divId: string = draggedDiv;

    let dm = document.getElementById( divId );
    ev.preventDefault();
    ev.stopPropagation();
  };


  // This makes the third box become droppable
  const allowDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  // additional reference for creating a dynamic list
  // https://www.java67.com/2022/01/how-to-create-dynamic-list-in-react.html


  // asynchronous/promises
  const fetchData = async() => {
    const resp = await fetch('');
    const data = await resp.json();
    console.log(data);
  }





























  const dataAosParams = "data-aos='fade-down' data-aos-offset='500' data-aos-delay='50' data-aos-duration='500' data-aos-easing='ease-in-out' data-aos-once='false'";

  /**
   * @abstract Handle the mouseover for each menu item
   * @param e 
   */
  const showMenuButtonDescription = (e) => {

    if(e.target.id) {
      console.log("showMenuButton: " + e.target.id);
      
      /** 
       * highlight the menu button 
       */
      let targetElement = $("#" + e.target.id);
      targetElement.css('background-color', 'var(--buttonHighlightColor)');

      let menuNum = e.target.id.slice(-2);    
      let section = "section" + menuNum;
      console.log('section = ' + section);
     
      /** 
       * get button references 
       */
      let divChild = targetElement.children();
      let divChildDescription = divChild[1].innerText;
      console.log(divChildDescription);
      
      if (divChildDescription && divChildDescription != null) { 
        /** 
         * define the style object to be used in the div 
         */
        let div = document.createElement( 'div' );
        div.id = 'temp';
        div.style.width = "320px";
        div.style.height = "110px";
        div.style.position = "absolute";
        div.style.color = '#BED0F1';
        div.style.textAlign = 'left';

        if (targetElement) {
          let index = parseInt(menuNum);
          handlePositionChange(index);
  
          // https://www.logilax.com/javascript-get-html-element-position/
          div.style.top = (targetElement[0].getBoundingClientRect().top + 55) + "px";
  
          // assign the x dimension based on which menu is open
          div.style.left = index < 3 ? (targetElement[0].getBoundingClientRect().left + 160) + "px" : (targetElement[0].getBoundingClientRect().left - 330) + "px"
  
          div.innerHTML = "<div class='heebo' " + dataAosParams + ">"+divChildDescription+"</div>";
        }
  
        document.getElementById('12345').appendChild(div);
      }

      //$(divChild[0]).slideToggle(300);

    }
  }


  /**
   * @abstract Handle the mouseout for each menu item
   * @param e 
   */
  const hideMenuButtonDescription = (e) => {

    if(e.target.id) {
      let tempDiv = document.getElementById('temp');
      if (tempDiv) tempDiv.remove();

      let targetElement = $("#" + e.target.id);

      targetElement.css('background-color', 'var(--buttonColor)');      

      // THIS ISN'T EXACTLY WORKING EITHER
      let divChild = targetElement.children();

      //$(divChild[0]).slideUp(300);
    }
  }

  const closeMenu = (e) => {
    if(e.target.id) {

      let targetElement = $("#" + e.target.id);

      targetElement.css('background-color', 'var(--buttonColor)');      

      // THIS ISN'T EXACTLY WORKING EITHER
      let divChild = targetElement.children();

      $(divChild[0]).slideUp(300);
    }
  }


  /**
   * @abstract Handle clicking of each main menu item
   * @param e 
   */
  const handleMenuClick = (e) => {
    // get the ID of the DOM element that was clicked
    // use it to find the image path in the array
    //let divParent = $(".menuSubDiv");
    //divParent.slideUp(300);

    // this should add a transition to the div to 
    // expand and reveal menu items
    let divChild = $("#" + e.target.id).children();
    $(divChild[1]).slideToggle(300);
    console.log(divChild[1]);

  };



  /**
   * Handle clicking each process step
   * @param e 
   */
  const handleStepClick = (e) => {
    // get the ID of the DOM element that was clicked
    // use it to find the image path in the array
    const selectedDiv = Enumerable.from(processDivArray)
      .where(dd => dd.id === e.target.id)
      .toArray();

    const stepContent = (selectedDiv[0].content != null) ? selectedDiv[0].content : null;

    setflowStepObject(stepContent);

    console.log(flowStepObject);
  };

  /**
   * Update the content for the step: image and text
   */
  const updateImage = () => {
    if (flowStepObject != null) {
      /** 
       * flowStepObject is an array of objects that contain
       * an image path and some content
       */ 
      processStepDivImageArray = [];

      flowStepObject.map(obj => {
        processStepDivImageArray.push(<ImageDiv description={obj.imageDescription} image={obj.imagePath}/>);
      })
    }
  }

  /** 
   * Find the list of divs that matches the incremented group and load it
   */
  const updateDivArray = () => {
    processStepDivDisplayArray = [];
    processStepDivImageArray = [];

    const selectedDiv = Enumerable.from(processDivArray)
          .where(dd => dd.project === count)
          .toArray();

    for (var i=0; i<selectedDiv.length; i++) {
      let obj = selectedDiv[i];

      /** 
       * Add the steps to the display array
       */
      // https://stackoverflow.com/questions/60915262/how-to-pass-function-as-props-from-functional-parent-component-to-child 
      processStepDivDisplayArray.push(<ProcessStepDiv name={obj.id} backgroundColor={obj.backgroundColor} link={obj.imagePath} handler={handleStepClick} />);

      /** 
       * If there is more than one step then add
       * a connector between the step divs
       */
      if ( i+1 < selectedDiv.length) {
        processStepDivDisplayArray.push(<ProcessStepDivConnector />)
      }

      /** 
       * Preload the first image, simulating a
       * click on the first step
       */
      if (i === 0) { 
        const imageAndText = obj.content;
        imageAndText.map(c => {
          processStepDivImageArray.push(<ImageDiv description={c.imageDescription} image={c.imagePath}/>);
        })
      }
    }
  }






















  /** *********************************************************
   * DEFINE ALL USE EFFECTS
   *
   * https://blog.logrocket.com/react-reference-guide-hooks-api/#useeffect
   * https://www.linkedin.com/pulse/best-practices-useeffect-react-dawood-masood/
   * 
   *  *********************************************************
   */
  React.useEffect(() => {
    // useEffect is invoked after every render
    console.log('************');
    console.log('useEffect: updateDivArray');
  }, [elementGroup, updateDivArray()]);


  React.useEffect(() => {
    console.log('************');
    console.log('draggable Div Image useEffect invoked: ' + processStepDivImage);
    
  }, [flowStepObject, updateImage()]);


  React.useEffect(() => {
    console.log('************');
    console.log('set the UI variables');

    $(':root').css('--backgroundColor', backgroundColorValue);
    $(':root').css('--buttonColor', buttonColorValue);
    $(':root').css('--buttonTextColor', buttonTextColorValue);
    
  }, [backgroundColorValue, buttonColorValue, buttonTextColorValue]);
  




















  /** *********************************************************
   *  *********************************************************
   *  *********************************************************
   * 
   *  OUTPUT
   * 
   *  *********************************************************
   *  *********************************************************
   *  *********************************************************
   */
  return (  

    <div id='12345' className="App" style={{ padding: '5px', backgroundColor: 'var(--backgroundColor)' }}>

      <head>
          <title>Chris Peterson - product owner developer business analyst artist</title>

          {/* metadata */}
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="keywords" content="Analyst, Business Analyst, Systems Analyst, Product Owner, CSPO, Product Designer, CSS, javaScript, typescript, c#, php, front end developer, mysql, node.js, three.js, babylon.js, metaverse, blockchain" />
          <meta name="author" content="Chris Peterson" />
          <meta name="subject" content="Chris Peterson online resume" />
          <meta name="description" content="advanced product owner product manager PO PM business analyst BA developer engineer PHP javascript typescript c sharp sql photoshop illustrator" />

          {/* load fonts */}
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@100&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Abel&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" rel="stylesheet" />


          {/* load the most current jqery from source site https://jquery.com/download/ */}
          <script src="https://code.jquery.com/jquery-3.7.1.min.js" integrity="sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo="></script>

          {/* load bootstrap CSS library */}
          <link href="./bootstrap532/css/bootstrap.css" rel="stylesheet" />

          {/* override the bootstrap CSS with my main stylesheet for the site */}
          <link rel="stylesheet" href="main2.css" />

          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" ></script>

      </head>

      <div className="contactSocial">
        {/*
          <a href="https://badgecert.com/bc/html/groupbadges.html?k=bXlNT29LdlJkTXA1OXd5M2FTb0RMams0cTkybW0yb2Q"><img alt='' src="./images/seal-advcspo.png" style={{width:'43px', marginRight: '.6rem'}} /></a>
        */}
          <a href="https://www.linkedin.com/in/chris-peterson-03b5557/" target="_blank"><img  alt='' src="./images/linkedin_icon.png" style={{width:'33px', margin: '.1rem .6rem 0 0'}} /></a>
          <a href="https://twitter.com/sonofpeter" target="_blank"><img  alt='' src="./images/x_icon.png" style={{width: '33px', margin: '.5rem .6rem 0 0'}} /></a>
          <a href="https://stackexchange.com/users/12147111/sonofpeter?tab=accounts" ><img  alt='' src="./images/stack_icon-sm.png" style={{width: '28px', margin: '.5rem .6rem 0 0'}} /></a>
      </div>



        {/* Menu Buttons */}
        <div className="menuContainerDiv">
          {
            menuArray.map((item, i) => (      
                <NavigationMenuDiv 
                  id={item.id} 
                  name={item.name} 
                  description={item.description} 
                  links={item.links} 
                  position={item.position} 
                  mouseOverHandler={showMenuButtonDescription} 
                  mouseOutHandler={hideMenuButtonDescription} 
                  mouseClickHandler={handleMenuClick}
                  mouseLeave={closeMenu}
                /> 
            ))
          }
        </div>


      {/** 
       * Menu content
       * https://github.com/michalsnik/aos#animations
       */}
      <div id="contentDiv" style={{
        display: 'flex',      
        width: '600px',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #2E3F6D',
        borderRadius: '5px',
        position: 'absolute',
        top: '400px'
        }}>

      </div> 








    















    {/** ******************************************************************************************************************
      *  ******************************************************************************************************************
      *  ******************************************************************************************************************
      * 
      *  CONTENT
      * 
      *  ******************************************************************************************************************
      *  ******************************************************************************************************************
      *  ******************************************************************************************************************
      */}

      <ResumeDiv></ResumeDiv>


    {/** 
      * CX
      */}
    <div id="section02" className="section02Content container-fluid">
      menu02
        <div id="uxAnalysisContent" className="uxAnalysis heebo">

            {/** 
             *  Menu preview
             */}
            <h4>all my cool UX stuff</h4>           

            <div className="textContent">
                {/** 
                  * PROCESS NAVIGATION
                  */}
                <div style={{left: '0px', width: '100%', zIndex: '999', backgroundColor: 'white', padding: '0 20px 0 20px', }}>
                  
                  {/** 
                    * PROCESS MENU SECTION
                    */}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                    {/** 
                     * Title and navigation
                     */}
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px'}}>
                      <div style={{ padding: '7px 15px 0 0'}}>
                        <button type="button" onClick={handleDecrease}>Previous</button>  
                      </div>
                      <div className="App-header">
                        { projectList[count-1].name }
                      </div>
                      <div style={{ padding: '7px 0 0 15px'}}>
                          <button type="button" onClick={handleIncrease}>Next</button> 
                      </div>
                    </div>

                    <br />

                    {/** 
                     * Process steps
                     */}
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                      <div className="flowDiagram">
                        {processStepDivDisplayArray}
                      </div>
                    </div>

                  </div>

                </div>

                {/** 
                  * PROCESS CONTENT
                  * Need to iterate through multiple images and content here
                  * processStepDivImage should actually be an array
                  */}
                <div className="flowImages">
                  {processStepDivImageArray}
                </div>

            </div>           
            
        </div>

        <div id="iconContent" className="icon heebo" >
            <br /><br />
            <h4>Icon Design</h4>
            <div className="textContent">
              stuff that is written here
            </div>
        </div>
    </div>



    {/** 
      * ART
      */}
    <div id="section03" className="section03Content container-fluid">
      menu03
        <div id="artContent" className="art">
            <br /><br />
            {/** 
             *  Menu preview
             */}
            <h4 style={{color: "white"}}>Artist, Medical Illustrator, Film Maker and Photographer</h4>          
            
            <div className="textContent">
              This is the content you will see when you visit my artsy artsy page

                <br />
            </div>
        </div>
    </div>



    {/** 
      * CONTACT: SECTION 1
      */}
    <div id="section04" className="menu04Content container-fluid">
        <div id="contact" className="contact">
            <br /><br />
            {/** 
             *  Menu preview
             */}
            <div id="menu04Content" className="menu04Content">
              <h4 style={{color: "white"}}> </h4>
              chris@chrissonofpeter.com
            </div>            
            
            <div className="textContent">
              This is the content you will see when you visit my artsy artsy page

                <br />
            </div>
        </div>
    </div>




    { /* Read more: https://www.java67.com/2022/01/how-to-create-dynamic-list-in-react.html#ixzz7gO4xK1Cs */ }  

    {/* end parent */}
    </div>

  );
}


export default App;