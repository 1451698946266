import React from 'react';
/** 
 * Import tools and libraries
 */
import $ from "jquery";
import Enumerable from '../lib/linq.min';




/** 
 * @abstract Creates a menu div box with rounded corners and some kind of content. 
 * @param props
 */
function ResumeDiv <HTMLDivElement>(props: any) {
    /** 
     * define content properties of the div 
     */
    let section: string = props.section;

    /** define the style object to be used in the div */
    const divStyle: any = {
        zIndex: '999',
        margin: '10px',
    };

    /** 
     * define the div itself 
     */
    return ( 

        <div id="menu01Content" className="section01Content container-fluid">

            {/* main content */}
            <div id="mainContent" className="main heebo" data-aos='fade-left' data-aos-offset='500' data-aos-delay='50' data-aos-duration='500' data-aos-easing='ease-in-out' data-aos-once='false'>
                {/** 
                 *  Menu preview
                 */}
                <h4><span className='darker contentTitle'>Who I am</span></h4>

                <div className="textContent">
                    I am currently the Product Owner of two scrum teams, each with their own busy backlog. I run a two hour refinement session four days per week,
                    sticking to the Scrum best practice of allocating 10% of the sprint to refinement.
                    <br /><br />
                    I am constantly learning new things and new parts of the business. This includes business processes, legal constraints, compliance rules,
                    system processes, behind the scenes technical details, and customer experiences.
                    <br /><br />
                    On a daily basis I am learning about the needs of my stakeholders, sometimes having no previous experience in their area.
                    I imagine the thing they describe and document it diagrammatically so I can show my colleagues I understand. I then use it to
                    complete feature mapping: the diagram becomes the road map for feature development, user stories, acceptance criteria and automation
                    testing, all carefully managed with sprint planning.
                    <br /><br />
                    In addition to the Scrum Product Owner training, I also took
                    the Scrum Master training. Though I do not typically work in this role, I take on Scrum Master responsibilities when necessary.
                    <br /><br />
                    I am a person that people come to when there is an emergency and a fix in the system is needed ASAP. My manager recently asked me to lead a discussion concerning a particular
                    issue, and he asked that I drive the discussion and document the process. This is something I am now very adept at doing as I typically take notes in some form of a process
                    diagram. Once this was done and we identified the problem I wrote the acceptance criteria for the story that my team then worked on, and we turned it around - end to end -
                    in about 4 days. The development and validation in the lower environment did not take that long, but we did have to wait to get it into production.
                </div>

            </div>

            <div id="experienceContent" className="experience heebo" data-aos='fade-right' data-aos-offset='500' data-aos-delay='50' data-aos-duration='500' data-aos-easing='ease-in-out' data-aos-once='false'>
                <br /><br />
                <h4>Experience Timeline</h4>
                <div className="textContent">
                    <ul>
                        <li>Lead Product Manager since 2021 (<a target="_blank" href="https://certification.scrumalliance.org/accounts/1172759-chris-peterson/certifications/1346184-cspo">CSPO</a> and <a target="_blank" href="https://certification.scrumalliance.org/accounts/1172759-chris-peterson/certifications/1590451-advcspo">A-CSPO</a> through ScrumAlliance.org)</li>
                        <li>Lead Business Analyst since 2005</li>
                        <li>UX/UI Design since 2000</li>
                        <li>Scrum and Agile methodologies since 2017</li>
                        <li>Large Scale Scrum starting mid 2022</li>
                        <li>Developer/Product Designer 2021 - 2023 (Freelance)</li>
                        <li>SDLC management - attending release calls, making sure code is promoted to the correct release/branch, toggle management, UAT coordination</li>
                    </ul>
                    <br />
                </div>
            </div>

            <div id="skillsetContent" className="skillset heebo" data-aos='fade-right' data-aos-offset='500' data-aos-delay='50' data-aos-duration='500' data-aos-easing='ease-in-out' data-aos-once='false'>
                <br /><br />
                <h4>Skillset</h4>
                <div className="textContent">
                skillset stuff
                    <br />
                </div>
            </div>

            <div id="certificationsContent" className="certifications heebo" data-aos='fade-right' data-aos-offset='500' data-aos-delay='50' data-aos-duration='500' data-aos-easing='ease-in-out' data-aos-once='false'>
                <br /><br />
                <h4>Certifications</h4>
                <div className="textContent">
                certifications stuff
                    <br />
                </div>
            </div>

        </div>

    );
}

export default ResumeDiv;