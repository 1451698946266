import React from 'react';

function ProcessStepDiv <HTMLDivElement>(props: any) {
    
    /** define content properties of the div */
    let name: string = props.name;
    let description: string = props.description;

    /** define position and style attributes */
    let link: string = props.link;
    let func: any = props.handler;
    
    /** define the style object to be used in the div */
    const divStyle: any = {
      width: (name.length * 50) + 'px',
      hight: '50px',
    };

    /** define the div itself */
    return (<div id={name} className="flowStep" style={divStyle} onClick={func}>{name}</div>);
  }

  export default ProcessStepDiv;