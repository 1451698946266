import React from 'react';
  
function ImageDiv <HTMLDivElement>(props: any) {

    /** define content properties of the div */
    let name: string = props.name;
    let description: string = props.description;
    let imageLink: string = props.image;

    /** width and height settings */
    let imageWidth: number = props.width;
    let imageHeight: number = props.height;

    const imageStyle: any = {
        width: "calc(var(--flowImageWidth))",
    }

    /** define the style object to be used in the div */
    const divStyle: any = {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        maxWidth: "700px",
        paddingTop: "20px",
    };

    /** define the style object to be used for the text */
    const textDivStyle: any = {
        textAlign: "left",
        padding: "0 10px 0 10px",
        whiteSpace: "pre-line",
    };

    /** define the style object to be used for the text */
    const imageDivStyle: any = {
        textAlign: "center",
    };

    /** define the div itself */
    return (<div style={divStyle}><div style={textDivStyle}>{description}</div><br /><div style={imageDivStyle}><img src={imageLink} style={imageStyle} alt={name}/></div></div>);
}

export default ImageDiv;